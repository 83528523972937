<template>
  <div>
    <div>
      <div  class="full-image bottom" v-bind:style="{ backgroundImage: 'url(' + anotherImage + ')' }"></div>
      <div class="citation">
        <div class="page-content" style="padding-top: 2.4em">

          <div style="display: flex; justify-content: center">
            <v-icon color="white" class="open-quote">{{ icons.mdiFormatQuoteOpen }}</v-icon>
            <p class="citation-text">
              {{ selectedQuote.text }}
            </p>
            <v-icon color="white" class="closing-quote">{{ icons.mdiFormatQuoteClose }}</v-icon>
          </div>

          <div class="signature">{{ selectedQuote.author }}</div>
        </div>
      </div>
    </div>
    <footer>
      <div class="page-content in-column">
        <img :src="`${require('@/assets/logos/logo_drogues_et_societe.png')}`" alt="Drogues et Société" class="logo" />
        <div class="links">
          <ul>
            <li> <router-link to="/">Nous contacter</router-link></li>
            <li><router-link to="/">Nos services</router-link></li>
            <li><router-link to="/">Nos domaines d'intervention</router-link></li>
          </ul>
          <ul>
            <li><router-link to="/">Nos partenaires</router-link></li>
            <li><router-link to="/">Resources documentaires</router-link></li>
          </ul>
        </div>
        <slot></slot>
      </div>
    </footer>
  </div>

</template>
<script>
import { mdiFormatQuoteClose, mdiFormatQuoteOpen, } from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiFormatQuoteClose,
        mdiFormatQuoteOpen
      },
      quotes: [
        {"author": "Markos Zafiropoulos", "text": "La vie est une aventure audacieuse ou elle n'est rien du tout."},
        { "author": "Oscar Wilde", "text": "Soyez vous-même, les autres sont déjà pris." },
        {"author": "Albert Einstein", "text": "La créativité est contagieuse, faites-la tourner." }
      ],
      selectedQuote: null,
    }
  },
  computed: {
    innerWidth() {
      return window.innerWidth
    },
    anotherImage() {

      if (this.innerWidth <= 420) return require('@/assets/des-man-crowd.jpeg')
      else if (this.innerWidth <= 640) return require('@/assets/des-man-crowd.jpeg')
      else if (this.innerWidth <= 920) return require('@/assets/des-man-crowd.jpeg')
      else return require('@/assets/des-man-crowd.jpeg')
    },
  },
  methods: {
    selectQuote() {
      let number = Math.floor(Math.random() * this.quotes.length);
      this.selectedQuote = this.quotes[number]
    }
  },
  beforeMount() {
    this.selectQuote()
    this.intervalId = window.setInterval(this.selectQuote, 30000)
  },

  beforeDestroy() {
    window.clearInterval(this.intervalId)
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.full-image {

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 600px;
}

.citation {
  color: $white;
  background-color: $blue;

  .open-quote, .closing-quote {
    font-size: 40px;;
  }

  .open-quote {
    align-self: start;
    margin-top: -11px;
    margin-left: -20px;
    margin-right: 0.6em
  }

  .closing-quote {
    margin-bottom: -10px;
    margin-left: 0.6em
  }

  .citation-text {
    text-align: center; font-size: larger;
    margin-bottom: 0;
  }

  .signature {
    margin-top: 2em;
    font-size: larger; font-weight: bold; text-align: end; align-self: end; padding-right: 18em;
  }
}

footer {
  background-color: $footer-background-color;

  .logo {
    height: 50px;
  }

  .links {
    display: flex;

    ul {
      flex: 1;
      padding: 0;
      list-style: none;
      margin: 0 2em 0 0;
    }
  }
}

@media only screen and (max-width: 700px) {

  .citation {
    font-size: small;
    padding: 2em;

    .open-quote {
      align-self: start;
      margin-top: -11px;
      margin-left: -20px;
    }

    .closing-quote {
      margin-bottom: -10px;
      margin-left: 5px;
      align-self: flex-end;
    }

    .signature {
      font-size: larger; font-weight: bold; text-align: end; align-self: end;
      padding-right: 0;
    }
  }
}
</style>
