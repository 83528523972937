<template>
  <div class="home">
    <div  class="header bottom" v-bind:style="{ backgroundImage: 'url(' + backgroundImage + ')' }"></div>
    <div class="page in-column">

      <section class="page-content main-section">
        <div style="flex: 1">
          <p class="main-text">
            L’association Drogues et Société est engagée dans l’accompagnement médico-social et l’aide à l’insertion de publics usagers de drogues, la prévention et la réduction des risques liés aux conduites addictives. C’est aussi un lieu de recherche, de participation et de formation sur les problèmes liés aux drogues.
          </p>
<!--          <p>Certaines photographies et textes qui illustrent les pages de ce site ont été réalisées dans le cadre d’ateliers animés par Maxime <Couturier class=""></Couturier></p>-->
          <p><a>Télécharger notre dernier rapport d'activité</a></p>
        </div>

        <div style="flex: 1;">
          <h1>Le mot de l'association</h1>
          <div style="white-space: pre-wrap" v-html="edito"></div>
        </div>
      </section>

      <section>
        <div class="title-block">
          <div class="page-content">
            <h1>Découvrez nos services</h1>
          </div>
        </div>

        <div style="background-color: white">
          <div class="page-content">

            <p>L’association Drogues et Société gère deux services :</p>
            <div class="services">
              <Service :logo="`${require('@/assets/logos/logo_epice.png')}`" alt="Epice" with-link to="/nos-services/epice">
                <template v-slot:description>
                  <router-link to="/nos-services/epice"><strong>EPICE</strong></router-link> est un Centre de Soin, d’Accompagnement et de Prévention en Addictologie. Nous y accueillons des personnes présentant des usages problématiques de produits psychoactifs.
                </template>
              </Service>
              <Service :logo="`${require('@/assets/logos/logo_iap.png')}`" alt="IAP" with-link to="/">
                <template v-slot:description>
                  <router-link to="/nos-services/iap"><strong>IAP</strong></router-link> est un lieu-ressource intégrant une Consultation Jeunes Consommateurs et développant des actions de prévention, de formation et de recherche.
                </template>
              </Service>
<!--              <Service :logo="`${require('@/assets/logos/cjc_logo.png')}`"-->
<!--                       alt="Consultation Jeunes Consommateurs"-->
<!--                       with-link to="/">-->
<!--                <template v-slot:description>-->
<!--                  <router-link to="/"><strong>Consultation Jeunes Consommateurs</strong></router-link> un service d’accueil et de prévention pour une population jeunes.-->
<!--                </template>-->
<!--              </Service>-->
            </div>
          </div>
        </div>

      </section>

      <section>
        <div class="title-block">
          <div class="page-content">
            <h1>Nos champs d'intervention</h1>
          </div>
        </div>


          <div id="soin" class="action-row">

            <ActionBlock title color="green">Soin</ActionBlock>
            <ActionBlock large>
              <div class="action-text">
                <h3>Accès aux soins</h3>
                <p>Les usagers de drogues sont exposés à un certain nombre de risques sanitaires, pathologies et troubles psychiques. Dans le même temps, du fait-même de leur addiction, ils se voient souvent exclus du système de soins. La proposition de réponses adaptées implique un travail en réseau intégrant différentes spécialités.</p>
                <h3>Réduction des Risques et des Dommages</h3>
                <p>La politique de Réduction des Risques (RDRD), développée en France depuis la fin des années quatre-vingt, permet de prévenir certains risques par l’accès à des matériels de consommation et la prescription de traitements de substitution aux opiacés. Cette politique doit cependant être relayée en proximité, à partir de stratégies locales, par la diffusion d’informations auprès des publics concernés et la sensibilisation des acteurs médico-sociaux.</p>
                <router-link to="/">En savoir plus</router-link>
              </div>
            </ActionBlock>
          </div>

          <div id="accompagnement" class="action-row">
            <ActionBlock>
              <div class="action-text">
                <h3>Accompagnement social</h3>
                <p>Les conséquences sociales et sanitaires des usages problématiques de drogues conduisent souvent à l’exclusion d’un très grand nombre d’usagers. La prise en charge sociale doit s’inscrire dans une approche globale et être étayée d’un véritable accompagnement concret, souvent au long cours, dans une perspective de meilleur accès au droit commun et de reconstruction d’un projet de vie, de participation sociale et de citoyenneté active.</p>
                <router-link to="/">En savoir plus</router-link>
              </div>
            </ActionBlock>
            <ActionBlock title color="yellow">Accompagnement social</ActionBlock>
            <ActionBlock :img="`${require('@/assets/des-action-puzzle.jpeg')}`"></ActionBlock>
          </div>

          <div id="insertion" class="action-row">
            <ActionBlock :img="`${require('@/assets/des-action-hands.png')}`"></ActionBlock>
            <ActionBlock>
              <div class="action-text">
                <h3>Insertion et accès à l’emploi</h3>
                <p>L’élaboration d’un projet professionnel peut aider à renforcer un projet de soin et favoriser la régulation de consommations problématiques. L’hypothèse, souvent vérifiée, est que le soin n’est pas un préalable à l’insertion.</p>
                <router-link to="/">En savoir plus</router-link>
              </div>
            </ActionBlock>
            <ActionBlock title color="orange">Insertion</ActionBlock>
          </div>

        <div id="prevention" class="action-row">

          <ActionBlock title color="blue">Prévention</ActionBlock>
          <ActionBlock large>
            <div class="action-text">
              <h3>La prévention au quotidien</h3>
              <p>Une démarche de prévention des addictions doit s’appuyer tant sur la diffusion d’informations relatives aux différents risques liés à l’usage et à l’abus de drogues, que sur le renforcement des compétences psycho-sociales des publics jeunes.                                                                                                                                Doivent ainsi pouvoir être appréhendées les capacités de ces publics en matière de prévention par les pairs, dans la perspective de leur valorisation.</p>
              <router-link to="/">En savoir plus</router-link>
            </div>
          </ActionBlock>
        </div>

        <div id="tapaj" class="action-row">


          <ActionBlock>
            <div class="action-text">
              <h3>Travail Alternatif Payé À la Journée</h3>
              <p>TAPAJ est un dispositif d’insertion permettant aux jeunes en rupture d’être rémunérés en fin de journée, pour une activité professionnelle qui ne nécessite pas de qualification ou d’expérience professionnelle particulière et ne les engage pas sur la durée.</p>
              <!-- TAPAJ apporte une réponse adaptée aux jeunes en rupture que leurs différentes problématiques sociales, éducatives et sanitaires tiennent éloignés des dispositifs de droit commun.  -->
              <router-link to="/">En savoir plus</router-link>
            </div>
          </ActionBlock>
          <ActionBlock title color="cyan">Chantier TAPAJ</ActionBlock>
          <ActionBlock>
            <div style="height: 100%; display: flex; justify-content: center; align-items: center">
              <img :src="`${require('@/assets/logos/logo-tapaj.png')}`" height="auto" width="80%" alt="logo TAPAJ"/>
            </div>
          </ActionBlock>
        </div>

        <div id="formation" class="action-row">
          <ActionBlock :img="`${require('@/assets/des-action-tree.png')}`"></ActionBlock>
          <ActionBlock large>
            <div class="action-text">
              <h3>Formation</h3>
              <p>De nombreux professionnels sont confrontés, en grande proximité et au quotidien, à des publics consommateurs. Il s’agit de considérer leurs savoirs et savoir-faire, de connaître leurs besoins afin de les aider à renforcer leurs compétences et au-delà, de construire un socle de connaissances communes et les bases d’un véritable travail en réseau sur le territoire.</p>
              <p><router-link to="/">En savoir plus</router-link></p>
            </div>
          </ActionBlock>
          <ActionBlock title color="purple">Formation</ActionBlock>

        </div>

        <div id="recherche" class="action-row">
          <ActionBlock title color="red">Recherche</ActionBlock>
          <ActionBlock large>
            <div class="action-text">
              <h3>Recherche</h3>
              <p>La complexité de la question des usages de drogues n’en facilite pas l’approche pour la plupart des acteurs sociaux. Il est indispensable de pouvoir expérimenter collectivement de nouvelles stratégies d’intervention, à partir de différents champs professionnels, et d’en dégager des enseignements nous permettant d’améliorer nos capacités d’accompagnement des publics. La recherche action participative est une méthode parfaitement adaptée à une telle démarche.</p>
              <router-link to="/">En savoir plus</router-link>
            </div>
          </ActionBlock>
        </div>
      </section>

    </div>
  </div>
</template>

<script>

import Service from "../components/Service";
import ActionBlock from "../components/ActionBlock";
export default {
  name: 'Home',
  components: {ActionBlock, Service},
  data() {
    return {
      openAction: 1,
      edito: require('@/assets/cms/edito-349b562f-8cc3-4417-9820-6eba4a320592.html')
    }
  },
  computed: {
    innerWidth() {
      return window.innerWidth
    },
    backgroundImage() {

      if (this.innerWidth <= 420) return require('@/assets/des-main-background-420.jpeg')
      else if (this.innerWidth <= 640) return require('@/assets/des-main-background-640.jpeg')
      else if (this.innerWidth <= 920) return require('@/assets/des-main-background-920.jpeg')
      else return require('@/assets/des-main-background-1980.jpeg')
    },


  },
  methods: {
    downloadActivityReport() {

    },
    isOpen(index) {
      return this.openAction === index
    },
    onOpen(index) {
      this.openAction = index === this.openAction ? null : index
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors.scss";

.action-row {

  display: flex;

  .action-text {
    padding: 2em
  }

}

.home {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 475px;

    .home-header-title {
      text-align: center;
      margin-top: 1em;
      margin-bottom: 1.5em;
      max-width: 34%;
    }

  }

  .main-section {
    display: flex;

    .main-text {
      font-size: x-large;
      padding-right: 3em;
    }

  }

  .actions-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .citation {
    color: $white;
    background-color: $blue;
    padding: 5em;
  }
}


.title-block {
  color: $white;
  background-color: $blue;
}

@media only screen and (max-width: 1500px) {

  .home {
    .header {
      height: 300px;
    }
  }
}



@media only screen and (max-width: 1327px) {

  .home {
    .header {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 1280px) {

  .action-row {
    .action-text {
      padding: 1em;

    }
  }
}


@media only screen and (max-width: 920px) {

  .action-row {
    .action-text {
      padding: 0.5em;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 700px) {


  .action-row {
    flex-direction: column-reverse;
    .action-text {
      padding: 2em;
    }
  }

  .home {
    .header {
      height: 250px;
    }

    .main-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .main-text {
        font-size: x-large;
        padding-right: 0;
      }

    }
  }

  #soin {

    flex-direction: column;

    .block.green {
      .dummy {
        margin-top: 30%;
      }

    }
  }

  #accompagnement {

  }

  #insertion {

  }

  #prevention {

    flex-direction: column;
  }

  #recherche {

    flex-direction: column
  }

  #tapaj {

  }

}

@media only screen and (max-width: 420px) {


}
</style>
