<template>
  <div :class="blockClass">
    <div class="dummy"></div>
    <div class="content" v-bind:style="{ backgroundImage: 'url(' + img + ')' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    large: Boolean,
    title: Boolean,
    color: String,
    img: String

  },
  data() {
    return {
      actionFrontClass: `action-front ${this.color}`,
    }
  },
  methods: {
    openActionBlock() {
      this.$emit("on-open", this.id)
    }
  },
  computed: {

    blockClass() {

      let clazz = 'block'
      if (this.color) clazz += ` ${this.color}`
      if (this.large) clazz += ' large'
      if (this.title) clazz += ' title'
      return clazz
    },
  },
}
</script>



<style lang="scss" scoped>
.block {
  display: inline-block;
  position: relative;
  min-width: calc(100% /3);

  .dummy {
    margin-top: 90%;
    /* 4:3 aspect ratio */
  }

  .content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
  }
}

.block.large {
  flex: 2;

  .dummy {
    display: none;
    /* 4:3 aspect ratio */
  }
}

.block.title {

  .content {
    flex: 1;
    padding: 0;
    color: white;
    font-size: 72px;
    line-height: 1em;
    font-weight: bold;
    height: 100%; width: 100%;
    margin-top: -40px;
    text-align: center;
    display: flex; align-items: center; justify-content: center;
  }
}

@media only screen and (max-width: 2100px) {

  .block.title {
    .content {
      font-size: 48px;
    }
  }
}

@media only screen and (max-width: 1500px) {

  .block.title {
    .content {
      font-size: 42px;
    }
  }
}



@media only screen and (max-width: 1280px) {

  .block.title {
    .content {
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: 920px) {
  .block.title {
    .content {
      font-size: 26px;
    }
  }

}

@media only screen and (max-width: 700px) {


  .block {
    display: inline-block;
    position: relative;
    min-width: auto;
//    min-height: calc(100% /3);;

    .dummy {
      margin-top: 45%;
      /* 4:3 aspect ratio */
    }

    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-position: center;
      background-size: cover;
    }
  }

  .block.large {
    flex: none;

    .dummy {
      display: block;
      margin-top: 55%;
    }

  }

  .block.title {
    .dummy {
      margin-top: 25%;
    }

    .content {
      margin-top: 0;
    }
  }



}

@media only screen and (max-width: 420px) {


}


</style>

<style lang="scss" scoped>
@import "src/styles/colors";

//.action-block:hover .action-wrapper {
//  transform: rotateY(180deg);
//}
//
.action-block.open {
  min-width: 100%;

  .action-wrapper {
    flex: 1;
    .action-front {
    }

    .action-description {
      display: flex;
      height: 100%;
      width: 100%;
      flex: 2;
      transition: width ease-in-out 2s;
    }
  }
}

.action-block {

  flex: 1;
  display: flex;
  //perspective: 1000px;
  background: transparent;
  cursor: pointer;
  min-width: calc(100% / 3);;
  max-width: calc(100%);

  //.action-wrapper, .action-description {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  -webkit-backface-visibility: hidden;
  //  backface-visibility: hidden;
  //}

  .action-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    //transition: transform 0.6s;
    //transform-style: preserve-3d;
    display: flex;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);


    .action-front {
      flex: 1;
      padding: 3em 0;
      color: white;
      font-weight: bold;
      height: 100%; width: 100%;
      display: flex; align-items: center; justify-content: center;
    }

    .action-description {
      text-align: start;
      flex-direction: column;
      //padding: 2em;
      width: 0;
      height: 0;
      display: none;
      background-color: $gray;
      box-sizing: border-box;
      //transform: rotateY(180deg);
    }


  }
}


</style>
