import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nos-services/iap',
    component: () => import(/* webpackChunkName: "activities" */ '../views/services/IAP.vue')
  },
  {
    path: '/nos-services/epice',
    component: () => import(/* webpackChunkName: "activities" */ '../views/services/Epice.vue')
  },
  {
    path: '/champs-dintervention',
    component: () => import(/* webpackChunkName: "actions" */ '../views/Actions.vue')
  },
  {
    path: '/publications',
    component: () => import(/* webpackChunkName: "publications" */ '../views/Publications.vue')
  },
  {
    path: '/contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
