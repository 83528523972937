import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2A435F',
                secondary: '#337ab7',
                accent: '#ef7f00',
                error: '#d10019',
                background: '#f7f8fa',
                orange: '#ef7f00',
                yellow: '#faba00',
                green: '#aeb821',
                red: '#d10019',
                cyan: '#61b5ff',
                blue: '#337ab7',
                purple: '#903391'
            },
        },
    },
    icons: {
        iconfont: 'mdiSvg',
    }
});
