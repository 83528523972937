<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">

    <v-app-bar app color="white" elevation="0" height="108">
      <div class="header">
        <img :src="`${require('@/assets/logos/logo_drogues_et_societe.png')}`"
             alt="Drogues et Société" class="logo"
             @click="$router.push('/')"
             style="cursor: pointer" />
        <v-spacer></v-spacer>

        <v-app-bar-nav-icon v-if="showHamburger" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <ul v-else class="memu">
          <li>
            <v-menu open-on-hover offset-y min-width="200" >
              <template v-slot:activator="{ on, attrs }">
                <span class="menu-link" v-bind="attrs" v-on="on">Nos Services</span>
              </template>

              <v-list>
                <v-list-item link to="/nos-services/epice">
                <v-list-item-title>Epice</v-list-item-title>
              </v-list-item>
                <v-list-item link to="/nos-services/iap">
                  <v-list-item-title>IAP</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </li>
          <li><router-link to="/champs-dintervention">Champs d'intervention</router-link></li>
          <li><router-link to="/publications">Publications</router-link></li>
          <li><router-link to="/contact">A propos</router-link></li>
        </ul>

      </div>
    </v-app-bar>
    <v-navigation-drawer
        disable-resize-watcher
        v-model="drawer"
        fixed
        app>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="red--text text--accent-4">
          <v-subheader>Nos services</v-subheader>
          <v-list-item link to="/nos-services/epice">
            <v-list-item-title>Epice</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/nos-services/iap">
            <v-list-item-title>IAP</v-list-item-title>
          </v-list-item>

          <v-subheader>Activités</v-subheader>
          <v-list-item to="/champs-dintervention">
            <v-list-item-title>Champs d'intervention</v-list-item-title>
          </v-list-item>
          <v-list-item to="/publications">
            <v-list-item-title>Publications</v-list-item-title>
          </v-list-item>

          <v-list-item to="/contact">
            <v-list-item-title>A propos</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

    <Footer>

      <template v-slot:default>
        <div class="services-footer" style="margin-top: 3em">
          <Service :logo="`${require('@/assets/logos/logo_epice.png')}`" alt="Epice" with-link to="/services/epice">
            <template v-slot:description>
              <p>42 rue Saint Simon, 94000 Créteil</p>
              <p>Du Lundi au Vendredi de 9h à 12h45<br />Sur rendez-vous l’après-midi</p>
              <p><v-icon>{{ icons.mdiPhone }}</v-icon>01.48.99.22.14<br />01.48.99.57.44<br /><v-icon>{{ icons.mdiFax}}</v-icon>01.48.99.29.96</p>
              <p>epice94@gmail.com</p>
            </template>
          </Service>
          <Service :logo="`${require('@/assets/logos/logo_iap.png')}`" alt="IAP" with-link to="/services/iap">
            <template v-slot:description>
              <p>58 rue Gustave Eiffel , 94000 Créteil</p>
              <p>Accueil sur rendez-vous<br />Lundi, mardi et jeudi 9h30 à 17h<br />Mercredi de 9h à 18h</p>
              <p><v-icon>{{ icons.mdiPhone }}</v-icon>01.43.53.04.36<br /><v-icon>{{ icons.mdiFax }}</v-icon>01.48.98.10.73</p>
              <p><v-icon>{{ icons.mdiEmail }}</v-icon>iap-prevention@wanadoo.fr</p>
            </template>
          </Service>
<!--          <Service :logo="`${require('@/assets/logos/cjc_logo.png')}`"-->
<!--                   alt="Consultation Jeunes Consommateurs">-->
<!--            <template v-slot:description>-->
<!--              <div style="text-align: center">-->
<!--                <p>58 rue Gustave Eiffel , 94000 Créteil</p>-->
<!--                <p>Accueil sur rendez-vous<br />Lundi, mardi et jeudi 9h30 à 17h<br />Mercredi de 9h à 18h</p>-->
<!--                <p><v-icon>{{ icons.mdiPhone }}</v-icon>01.43.53.04.36<br /><v-icon>{{ icons.mdiFax }}</v-icon>01.48.98.10.73</p>-->
<!--                <p><v-icon>{{ icons.mdiEmail }}</v-icon>iap-prevention@wanadoo.fr</p>-->
<!--              </div>-->

<!--            </template>-->
<!--          </Service>-->
        </div>
      </template>


    </Footer>
  </v-app>
</template>

<script>

import Footer from "./components/Footer";
import Service from "./components/Service";
import { mdiPhone, mdiFax } from "@mdi/js";
import { VueScreenSizeMixin } from 'vue-screen-size';

export default {
  name: 'App',
  mixins: [VueScreenSizeMixin],
  components: {Footer, Service},
  data(){
   return {
    icons: {
      mdiPhone,
      mdiFax
    },
     drawer: false,
     group: null,
     menu: [{
       name: "Accueil",
       url: "/"
     }, {
       name: "Nos Services",
       children: [{
         name: "Epice",
         url: "/nos-services/epice"
       }, {
         name: "IAP",
         url: "/nos-services/iap"
       }]
     }, {
       name: "Nos Actions",
       url: "/nos-actions"
     }, {
       name: "Publications",
       url: "/publications"
     }, {
       name: "A propos",
       url: "/contact"
     }],
   }
  },
  watch: {
    group() {
      this.drawer = false
    }
  },
  computed: {
    showHamburger() {
      return this.$vssWidth < 700
    }
  },

};
</script>


<style lang="scss">
@import "src/styles/colors";

.v-application {
  font-family: Avenir, sans-serif !important;
}
.v-toolbar__content {
  padding: 0.5em 1em !important;
}

html, body {
  color: $primary-text-color;
  background-color: $background-color;
  font-size: larger;
}

.page {
  display: flex;
  height: 100%;
  background-color: $background-color
}

.page-content {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
}

.page-content.no-padding {
  padding: 0;
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.services-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 2000px) {

  html, body {
    font-size: large;
  }

}

@media only screen and (max-width: 1600px) {

  html, body {
    font-size: medium;
  }

}

@media only screen and (max-width: 1327px) {

  .page-content {
    padding: 1em;
    margin: 0;
    max-width: none;
  }

  html, body {
    font-size: medium;
  }
}

@media only screen and (max-width: 920px) {

}

@media only screen and (max-width: 700px) {

  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .services-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .service {
      margin-bottom: 6em;
      .img-wrapper {
        align-items: flex-start;
      }

      .description-wrapper {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 420px) {


}

</style>

<style lang="scss" scoped>
@import "src/styles/colors";

.menu-link {
  text-decoration: none;
  color: $primary-text-color;
  transition: color .35s ease;
}

.menu-link:hover {
  color: $primary-color;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  .logo {
    height: 50px;
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    li {
      padding: 0 1rem;
    }

    a {
        color: $primary-text-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;

      }

      a:hover {
        color: $primary-color;
      }
  }
}

@media only screen and (max-width: 1327px) {


}

@media only screen and (max-width: 920px) {

  .header {

    .logo {
      height: 45px;
    }

    ul {

      a {
        padding: 0;
      }

      li {
        margin-right: 1em;
      }

      li:last-child {
        margin-right: 0;
      }


    }
  }

}

@media only screen and (max-width: 790px) {

  .header {

    .logo {
      height: 40px;
    }

    ul {
      li {
        margin-right: 0.5em;
      }
    }
  }

}

@media only screen and (max-width: 700px) {


}

@media only screen and (max-width: 420px) {


}

</style>
