<template>
  <div class="service">
    <div class="img-wrapper">
      <router-link :to="to"><img :src="logo" :alt="alt" /></router-link>
    </div>
    <div class="description-wrapper">
      <slot name="description"></slot>
      <div v-if="withLink" class="link-wrapper">
        <router-link :to="to">En savoir plus</router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Service",
  props: {
    logo: String,
    alt: String,
    withLink: Boolean,
    to: String
  }
}
</script>

<style lang="scss" scoped>
.service {
  flex: 1;
  margin-right: 2em;
  display: flex;
  //flex-direction: column;
  text-align: left;

  .img-wrapper {
    vertical-align: center;
    min-height: 179px;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-right: 2em;
    img {

      height: auto;
      max-width: 150px;
    }
  }

  .description-wrapper {
    text-align: left;
    max-width: 50%;
    margin-top: 1.7em;
  }

  .link-wrapper {
    flex: 0.2;
  }

}
</style>
